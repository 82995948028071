import { Button, ButtonProps } from "@mantine/core";
import { useState } from "react";

type SelectableButtonProps = {
  text: string;
  whenSelected: () => void;
  whenDeselected: () => void;
  initialSelectState: boolean;
} & ButtonProps;

type ButtonVariant = "selected" | "notSelected";

export const SelectableButton = (props: SelectableButtonProps) => {
  const [buttonSelected, setButtonSelected] = useState<ButtonVariant>(
    props.initialSelectState ? "selected" : "notSelected",
  );

  const handleClick = () => {
    if (buttonSelected === "selected") {
      setButtonSelected("notSelected");
      props.whenDeselected();
    } else {
      setButtonSelected("selected");
      props.whenSelected();
    }
  };

  const excludedProps = [
    "text",
    "whenSelected",
    "whenDeselected",
    "initialSelectState",
  ];

  // Create 'buttonProps' by omitting the excluded properties
  const buttonProps = Object.keys(props).reduce((result, key) => {
    if (!excludedProps.includes(key)) {
      // @ts-ignore
      result[key] = props[key];
    }
    return result;
  }, {});

  return (
    <Button
      {...buttonProps}
      color={buttonSelected === "selected" ? "blue" : "gray"}
      onClick={handleClick}
    >
      {props.text}
    </Button>
  );
};
