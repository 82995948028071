import React from "react";
import { useParams } from "react-router-dom";
import { StrengthValueUpdater } from "../components/StrengthValueUpdater";
import { useTrainings } from "../hooks/useTrainings";
import { usePlayers } from "../hooks/usePlayers";
import { Loader } from "@mantine/core";

type URLParams = {
  trainingId: string;
};

export const StrengthValueUpdaterForTraining = () => {
  let { trainingId } = useParams<URLParams>();

  const { useTraining } = useTrainings()
  const { useIdNameMap } = usePlayers()
  const { data: training, error: errorT, isLoading: isLoadingT } = useTraining(trainingId!)
  const { data: idNameMap, error: errorINP, isLoading: isLoadingINP } = useIdNameMap()

  if (errorT || errorINP) {
    return <h1>Nem sikerült betölteni az oldalt</h1>;
  }
  if (isLoadingT || isLoadingINP) {
    return <Loader />
  }

  return (
    <StrengthValueUpdater idNameMap={idNameMap!} trainingData={training!} />
  );

};
