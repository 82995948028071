import { useQuery } from "react-query";
import {
  ChallengePeriodsResponse,
  ChallengePointsForPeriodResponse,
} from "../api/Descriptors";
import { ChallengeClient } from "../api/Challenge";
import { Loader, Select } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { OrderableTable } from "../components/base/orderable-table";

export const MovingChallengeInfo = () => {
  const challengeClient = new ChallengeClient();
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [summedUpPoints, setSummedUpPoints] = useState<
    {
      athlete_strava_id: number;
      athlete_name: string;
      points: number;
      distance_swam: number;
    }[]
  >([]);
  const {
    isLoading: challengeLoading,
    error: challengeError,
    data: challengeData,
  } = useQuery<ChallengePeriodsResponse>({
    queryKey: ["challenge-periods"],
    queryFn: () => {
      return challengeClient.listChallengePeriods();
    },
  });
  const {
    isLoading: challengePointsLoading,
    error: challengePointsError,
    data: challengePointsData,
  } = useQuery<ChallengePointsForPeriodResponse>({
    queryKey: ["challenge-points", selectedPeriod],
    queryFn: () => {
      return challengeClient.getChallengePoints(parseInt(selectedPeriod || ""));
    },
    enabled: !!selectedPeriod,
  });

  const sumUpChallengePointsByStravaId = (
    d?: ChallengePointsForPeriodResponse
  ) => {
    if (!d || !d.data) {
      return [];
    }
    const result: {
      [key: number]: {
        athlete_strava_id: number;
        athlete_name: string;
        points: number;
        distance_swam: number;
      };
    } = {};
    d.data.forEach((v) => {
      if (result[v.athlete_strava_id]) {
        result[v.athlete_strava_id].points += v.points;
        result[v.athlete_strava_id].distance_swam += v.distance_swam;
      } else {
        result[v.athlete_strava_id] = {
          athlete_strava_id: v.athlete_strava_id,
          athlete_name: v.athlete_name,
          points: v.points,
          distance_swam: v.distance_swam,
        };
      }
    });
    return Object.values(result);
  };

  useEffect(() => {
    if (challengeData) {
      setSelectedPeriod(
        challengeData.challenge_periods?.at(0)?.id.toString() || ""
      );
    }
  }, [challengeData]);

  useEffect(() => {
    setSummedUpPoints(sumUpChallengePointsByStravaId(challengePointsData));
  }, [challengePointsData]);

  const columns = useMemo<
    Column<{
      athlete_strava_id: number;
      athlete_name: string;
      points: number;
      distance_swam: number;
    }>[]
  >(
    () => [
      {
        Header: "Strava azonosító",
        accessor: "athlete_strava_id",
      },
      {
        Header: "Név",
        accessor: "athlete_name",
      },
      {
        Header: "Pontok",
        accessor: "points",
      },
      {
        Header: "Úszott táv [m]",
        accessor: "distance_swam",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: summedUpPoints }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (challengeLoading || challengePointsLoading) {
    return <Loader />;
  }

  if (challengeError || challengePointsError) {
    return <div>Hiba történt: {challengeError}</div>;
  }

  return (
    <>
      <Select
        defaultValue={challengeData?.challenge_periods?.at(0)?.id.toString()}
        data={
          challengeData
            ? challengeData.challenge_periods
              ? challengeData.challenge_periods.map((period) => ({
                  label: `Hét ${period.first_week} - ${period.last_week}`,
                  value: period.id.toString(),
                }))
              : []
            : []
        }
        size="md"
        onChange={(value) => setSelectedPeriod(value)}
        value={selectedPeriod}
      />
      <br />
      <OrderableTable
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </>
  );
};
