import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { PlayerInfo } from "../api/Descriptors";
import { getPositionByValue } from "./position";


type UpdateFormProps = {
    playerInfo: PlayerInfo | undefined | null
}

export const usePlayerUpdateForm = (props: UpdateFormProps) => {
    const isValidTrainingStrength = (value: Number) => {
        // should be a number between 0 and 12
        const number = Number(value);
        return (number >= 0.0 && number <= 12.0) || number;
    }

    const form = useForm({
        initialValues: {
            fname: "Jakab",
            lname: "Gipsz",
            primaryPosition: getPositionByValue("1").label,
            secondaryPosition: getPositionByValue("2").label,
            trainingStrength: 5.0,
            status: "Aktív"
        },

        validate: (values) => {
            return {
                trainingStrength: !isValidTrainingStrength(values.trainingStrength) ? 'Az erősségnek 0 és 12 közé eső számnak kell esnie' : null,
            };
        },
    });

    useEffect(() => {
        if (props.playerInfo) {
            form.setValues({
                fname: props.playerInfo.fname,
                lname: props.playerInfo.lname,
                primaryPosition: getPositionByValue(props.playerInfo.primary_position).label,
                secondaryPosition: getPositionByValue(props.playerInfo.secondary_position).label,
                trainingStrength: props.playerInfo.training_strength ? props.playerInfo.training_strength : 5.0,
                status: props.playerInfo.status ? props.playerInfo.status : "Aktív"
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.playerInfo])


    return form;
}