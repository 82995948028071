import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GenericMessage,
  GetHockeyEventResponse,
  GetHockeyEventsResponse,
  UpdateHockeyEventRequest,
} from "../api/Descriptors";
import { HockeyEventsClient } from "../api/HockeyEvent";

const hockeyEventClient = new HockeyEventsClient();

export const useHockeyEvents = () => {
  const queryClient = useQueryClient();

  const useHockeyEvent = (eventId: number | string) => {
    return useQuery<GetHockeyEventResponse, Error>({
      queryKey: ["hockey-events", eventId],
      queryFn: () => hockeyEventClient.get(eventId.toString()),
    });
  };

  const useAllHockeyEvents = () =>
    useQuery<GetHockeyEventsResponse, Error>({
      queryKey: ["hockey-events"],
      queryFn: () => hockeyEventClient.getAll(),
    });

  const useUpdateHockeyEvent = (eventId: number | string) => {
    return useMutation<GenericMessage, Error, UpdateHockeyEventRequest>(
      async (payload: UpdateHockeyEventRequest) =>
        await hockeyEventClient.update(eventId.toString(), payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["hockey-events"]);
          queryClient.invalidateQueries(["hockey-events", eventId]);
        },
      },
    );
  };

  return {
    useHockeyEvent,
    useAllHockeyEvents,
    useUpdateHockeyEvent,
  };
};
