import { Container } from "@mantine/core"
import { Outlet } from "react-router"

export const StatsLayout = () => {
    return (
        <>
            <Container my={20}>
                <Outlet />
            </Container>
        </>
    )
}