const positions = [
    { value: "0", label: "Ismeretlen" },
    { value: "1", label: "Leghátsó" },
    { value: "2", label: "Középső" },
    { value: "3", label: "Első" },
    { value: "4", label: "Szélső" },
]

export const getPositions = (options: { includeUnknown: boolean }) => {
    if (options.includeUnknown) {
        return positions;
    }
    return positions.slice(1);
}

export const getPositionByLabel = (label: "Leghátsó" | "Középső" | "Első" | "Szélső" | string) => {
    if (["Leghátsó", "Középső", "Első", "Szélső"].includes(label) === false) {
        return positions[0];
    }
    const found = positions.find((pos) => pos.label.toLowerCase() === label.toLocaleLowerCase());
    if (found === undefined) {
        return positions[0];
    }
    return found;
}

export const getPositionByValue = (value: string | number | undefined | null) => {
    if (value === undefined || value === null) {
        return positions[0];
    }
    const found = positions.find((pos) => pos.value === value.toString());
    if (found === undefined) {
        return positions[0];
    }
    return found;
}
