import { Container } from "@mantine/core";
import React from "react";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export const AllTrainings: FC<{}> = () => {

	return (
		<>
			<Container my={20}>
				<Outlet />
			</Container>
		</>
	)
}