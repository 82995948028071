import { Anchor, Table } from "@mantine/core";
import { Row } from "react-table";

type OrderableTableProps = {
  getTableProps: () => any;
  headerGroups: any[];
  rows: Row<any>[];
  prepareRow: (row: any) => void;
  getTableBodyProps: () => any;
  linkAccessor?: {
    accessor: string;
    baseUrl: string;
  };
  caption?: string;
};

export const OrderableTable = (props: OrderableTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    props;

  return (
    <Table
      {...getTableProps()}
      striped
      highlightOnHover
      withRowBorders
      withTableBorder
      withColumnBorders
      captionSide="top"
    >
      {props.caption && <caption>{props.caption}</caption>}
      <Table.Thead>
        {headerGroups.map((headerGroup) => (
          <Table.Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <Table.Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{ textAlign: "center" }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </span>
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Table.Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Table.Td {...cell.getCellProps()}>
                  {cell.column.id !== props.linkAccessor?.accessor ? (
                    cell.render("Cell")
                  ) : (
                    <Anchor
                      href={`${props.linkAccessor?.baseUrl}/${cell.value}`}
                      c="var(--mantine-color-blue-5)"
                    >
                      {cell.render("Cell")}
                    </Anchor>
                  )}
                </Table.Td>
              ))}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
