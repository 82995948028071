import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GenericMessage,
  GetPlayerStreakDataResponse,
  NewPlayerInput,
  NewPlayerResponse,
  PairingInfo,
  PlayerIdNameMap,
  PlayerInfo,
  WinningPercentageInfo,
} from "../api/Descriptors";
import { PlayersClient } from "../api/Players";

const playersClient = new PlayersClient();

export const usePlayers = () => {
  const queryClient = useQueryClient();

  const useIdNameMap = () =>
    useQuery<PlayerIdNameMap, Error>({
      queryKey: ["players", "idnamemap"],
      queryFn: playersClient.getIdNameMap,
    });

  const useAllPlayers = (options?: { status?: "active" | "inactive" }) =>
    useQuery<PlayerInfo[], Error>({
      queryKey: "players",
      queryFn: () => playersClient.getAll(options ?? {}),
    });

  const usePlayerTrainingStreak = () =>
    useQuery<GetPlayerStreakDataResponse, Error>({
      queryKey: ["players", "trainings"],
      queryFn: () => playersClient.getTrainingStreak(),
    });

  const useWinningPercentageStats = () =>
    useQuery<WinningPercentageInfo, Error>({
      queryKey: ["players", "winningpercentage"],
      queryFn: playersClient.getWinningPercentageStats,
    });

  const useCreateNewPlayer = () =>
    useMutation<NewPlayerResponse, Error, NewPlayerInput>(
      async (payload: NewPlayerInput) => await playersClient.createNew(payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("players");
          queryClient.invalidateQueries("idnamemap");
          queryClient.invalidateQueries("winningpercentage");
        },
      },
    );

  const usePlayer = (playerId: number | string) =>
    useQuery<PlayerInfo, Error>({
      queryKey: ["players", playerId],
      queryFn: () => playersClient.getSingle(playerId.toString()),
    });

  const useUpdatePlayer = (playerId: number) =>
    useMutation<GenericMessage, Error, NewPlayerInput>(
      async (payload: NewPlayerInput) =>
        await playersClient.updateSingle(playerId.toString(), payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("players");
          queryClient.invalidateQueries("idnamemap");
        },
      },
    );

  const usePairing = (playerId: number | string) =>
    useQuery<PairingInfo, Error>({
      queryKey: ["players", "pairings"],
      queryFn: () => playersClient.getPairingForPlayer(playerId.toString()),
    });

  return {
    useIdNameMap,
    useAllPlayers,
    useWinningPercentageStats,
    useCreateNewPlayer,
    usePlayer,
    useUpdatePlayer,
    usePairing,
    usePlayerTrainingStreak,
  };
};
