import React, { ReactElement, ReactNode } from 'react';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from '../api/Login';


interface RequireLoginTokenProps {
    children: ReactNode;
}

export const RequireLoginToken: FC<RequireLoginTokenProps> = ({ children }: RequireLoginTokenProps): ReactElement<any, any> => {
    const location = useLocation();
    const auth = getToken();

    if (!auth) {
        return <Navigate to={`/login?redirectTo=${location.pathname}`} />
    }

    return <>{children}</>;
}