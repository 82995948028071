export const convertStatus = (status: string | undefined) => {
  if (status === undefined) {
    return "Ismeretlen";
  }
  if (
    status.toLowerCase() === "inactive" ||
    status.toLowerCase() === "inaktív"
  ) {
    return "Inaktív";
  }
  return "Aktív";
};

export const renderDate = (date: string | undefined) => {
  if (date === undefined) {
    return "Ismeretlen";
  }
  return new Date(date).toLocaleDateString("hu-HU");
};

export const formatDate = (date: Date | string | undefined) => {
  if (date === undefined) {
    return "Ismeretlen";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return date.toISOString().split("T")[0].replaceAll("-", ".");
};
