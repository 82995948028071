import { KyInstance } from "ky/distribution/types/ky";
import { API_URL } from "./Config";
import {
  GenericMessage,
  LatestStrengthValuesForPlayersResponse,
  UpsertStrengthValuesRequest,
} from "./Descriptors";
import { Utils } from "./Utils";
import ky from "ky";
import { getToken } from "./Login";

export class StrengthValuesAPI {
  api: KyInstance;
  utils: Utils;
  strengthValuesBaseUrl: string;

  constructor() {
    this.utils = new Utils();
    this.strengthValuesBaseUrl = `${API_URL}/strength-values`;
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  async getLatestStrengthValuesForPlayers(
    playerIds: number[] | string[],
    upToDate: Date
  ) {
    const response = await this.api.get(
      `${this.strengthValuesBaseUrl}/latest-for-players/`,
      {
        searchParams: {
          ids: playerIds.join(","),
          date: upToDate.toISOString(),
        },
      }
    );
    return (await response.json()) as LatestStrengthValuesForPlayersResponse;
  }

  async update(payload: UpsertStrengthValuesRequest) {
    return (await this.api
      .put(`${this.strengthValuesBaseUrl}/`, { json: payload })
      .json()) as GenericMessage;
  }
}
