import React from "react";
import { FC } from "react"

export const Rules: FC<{}> = () => {
	return (
		<div>
			<h1>Szabályok</h1>
			<p>
				A vízihokit egy 25 x 15 méteres, legalább 2 méter mély medence alján
				játsszák.Egy csapat 10 főből áll. Ebből hat az aktív játékos és négy a
				csere. A meccs folyamán folyamatos a csere. Egy mérkőzés kétszer 15
				perces félidőből áll. A játékosok uszonnyal, maszkkal és légzőcsővel
				vannak felszerelve. A játék lényege, hogy egy másfél kilós műanyaggal
				bevont ólomkorongot a játékos az ütőjével az ellenfél 3 méteres
				kapujába juttassa. Három bíró vezeti a mérkőzést. Ebből kettő a
				vízben, egy - a főbíró - a partról irányítja a játékot. A bírók
				kézjelekkel kommunikálnak. A főbíró egy víz alatt is hallható
				hangjelzéssel állítja le, vagy indítja el a játékot. A játék szabályai
				megengedik a kemény és energikus játékot, de határozottan fellépnek a
				durva, sportszerűtlen és passzív játék ellen. A szankció szabadlövés,
				büntető, vagy kiállítás is lehet.
			</p>
			<h1>Felszerelés</h1>
			<p>Uszony, maszk, pipa, kesztyű, ütő, vízilabdás sapka.</p>
		</div>
	)
}