import { Card, Divider, SimpleGrid, Text } from "@mantine/core";
import { FC } from "react";

type TeamInfo = {
  name: string;
  logoPath: string;
  trainingTimes: string[];
  location: { name: string; exact: string };
};

export const Teams: FC<{}> = () => {
  const teamInfos: TeamInfo[] = [
    {
      name: "Egyszusz VSE",
      logoPath: "/egyszusz_logo/180x180.png",
      trainingTimes: ["Hétfő 19:30-21:30", "Szerda 19:30-21:30"],
      location: {
        name: "Duna Aréna",
        exact: "Budapest, Népfürdő u. 36",
      },
    },
    {
      name: "Pécsi Piranha",
      logoPath: "/piranha_logo/300x238.png",
      trainingTimes: [
        "Kedd 20:00-21:00",
        "Csütörtök 21:00-22:00",
        "Vasárnap 18:00-20:00",
      ],
      location: {
        name: "Hullámfürdő",
        exact: "Pécs, Szendrey Júlia u. 7",
      },
    },
  ];

  return (
    <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="xl">
      {teamInfos.map((teamInfo) => {
        return (
          <Card
            withBorder
            shadow="md"
            radius="md"
            p="xl"
            key={teamInfo.name}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: 350,
            }}
          >
            <Text fw={500}>{teamInfo.name}</Text>
            <Divider style={{ margin: "1rem" }} />
            <Text>Edzések:</Text>

            {teamInfo.trainingTimes.map((time) => {
              return (
                <b key={time}>
                  {time}
                  <br />
                </b>
              );
            })}
            <p>
              <b>
                Helyszín:
                <br />
              </b>
              {teamInfo.location.name}
              <br />
              {teamInfo.location.exact}
            </p>
          </Card>
        );
      })}
    </SimpleGrid>
  );
};
