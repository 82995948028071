import { Loader, Table } from "@mantine/core";
import { useQuery } from "react-query";
import { UsersClient } from "../../api/Users";

export const UsersList = () => {
  const usersClient = new UsersClient();
  const { error, data, isLoading } = useQuery({
    queryKey: "users",
    queryFn: () => usersClient.getAllUsers(),
  });

  if (error) {
    return <h2>Nem sikerült betölteni a felhasználókat az adatbázisból</h2>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Table withRowBorders withTableBorder withColumnBorders striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Azonosító</Table.Th>
            <Table.Th>Név</Table.Th>
            <Table.Th>Felhasználónév</Table.Th>
            <Table.Th>Strava azonosító</Table.Th>
            <Table.Th>Játékos azonosító</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data?.data.map((user) => {
            return (
              <Table.Tr key={user.strava_id}>
                <Table.Td>
                  <a href={`/users/${user.id}`}>{user.id}</a>
                </Table.Td>
                <Table.Td>{`${user.strava_last_name} ${user.strava_first_name}`}</Table.Td>
                <Table.Td>{user.username}</Table.Td>
                <Table.Td>{user.strava_id}</Table.Td>
                <Table.Td>{user.player_id}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
};
