export class Utils {
  async apiCall<T>(url: string): Promise<T> {
    const resp = await fetch(url);
    if (!resp.ok) {
      console.log("error while querying url", url, ":", resp.text);
    }
    return await resp.json();
  }

  async postApiCallWithPayload<T>(url: string, payload: any): Promise<T> {
    const resp = await fetch(url, {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    return await resp.json();
  }

  async putApiCallWithPayload<T>(url: string, payload: any): Promise<T> {
    const resp = await fetch(url, {
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });
    return await resp.json();
  }

  async deleteApiCall<T>(url: string): Promise<T> {
    const resp = await fetch(url, {
      method: "DELETE",
    });
    return await resp.json();
  }

  convertToDate(d: string) {
    let date = new Date(d);
    return date;
  }
}
