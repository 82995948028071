import { useEffect, useState } from "react";
import { Button, Loader, Table } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import {
  LatestStrengthValuesForPlayersResponse,
  PlayerIdNameMap,
  TrainingData,
  UpsertStrengthValuesRequest,
} from "../api/Descriptors";
import { StrengthValuesAPI } from "../api/StrengthValues";

export type PlayerAndStrengthInfo = {
  id: number;
  name: string;
  strength: number;
};

type PlayerAndStrengthInfoWithValues = {
  id: number;
  name: string;
  strengthBefore: number;
  strengthAfter: number;
};

export type StrengthValueUpdateProps = {
  trainingData: TrainingData;
  idNameMap: PlayerIdNameMap;
};

export type TeamInfo = {
  blackTeamPlayers: PlayerAndStrengthInfo[];
  whiteTeamPlayers: PlayerAndStrengthInfo[];
  blackTeamScore: number;
  whiteTeamScore: number;
};

export const StrengthValueUpdater = (props: StrengthValueUpdateProps) => {
  const strengthModifier = 0.05;
  const scoreModifier = 0.05;

  let [isLoaded, setIsLoaded] = useState(false);
  let [error, setError] = useState(null);
  let [strengthValues, setStrengthValues] =
    useState<LatestStrengthValuesForPlayersResponse>({});
  const navigate = useNavigate();

  // positive value always means white team gets point, negative means black
  // in calculation we will always add (+) the calculated number to white and
  // substract it from black
  const calculateModification = (prop: TeamInfo) => {
    // if white is the stronger team, then we want the teamStrengthDiff value to be negative, because if the
    // two teams played a draw, white should lose some rating
    let teamStrengthDiff =
      getTeamStrength(prop.blackTeamPlayers) -
      getTeamStrength(prop.whiteTeamPlayers);
    let teamStrengthDelta = teamStrengthDiff * strengthModifier;

    // if white scored more goals, than we want the scoreDiff value to be positive, because if the two teams
    // are equal in strength, then the winning team should gain rating
    let scoreDiff = prop.whiteTeamScore - prop.blackTeamScore;
    let scoreDelta = scoreDiff * scoreModifier;

    // couple of scenarios
    // 1) white is stronger by 1 point and won by 1 goal -> expected to stay the same rating
    //	  teamsStrengthDelta = 0.05 * (-1) => -0.05, scoreDelta = 0.05 * 1 => 0.05
    // 	  return -0.05 + 0.05
    // 2) white is stronger by 1 point and lost by 1 goal -> extected to lose "a lot"
    //	  teamsStrengthDelta = 0.05 * (-1) => -0.05, scoreDelta = 0.05 * (-1) => -0.05
    // 	  return (-0.05) + (-0.05) => -0.1 (ergo white lost 0.1 rating)
    // 3) white is stronger by 1 point and won by 2 goal -> extected to gain "a bit"
    //	  teamsStrengthDelta = 0.05 * (-1) => -0.05, scoreDelta = 0.05 * 2 => 0.1
    // 	  return (-0.05) + 0.1 => 0.05 (ergo white gained 0.05 rating)
    return teamStrengthDelta + scoreDelta;
  };

  const getUpdatedStrengthScoreForTeam = (
    team: PlayerAndStrengthInfo[],
    delta: number,
  ) => {
    const enrichedList = [] as PlayerAndStrengthInfoWithValues[];
    team.forEach((player) => {
      const newPlayerAndStrengthInfo: PlayerAndStrengthInfoWithValues = {
        id: player.id,
        name: player.name,
        strengthBefore: player.strength,
        strengthAfter: player.strength + delta,
      };
      enrichedList.push(newPlayerAndStrengthInfo);
    });
    return enrichedList;
  };

  const getTeamStrength = (team: PlayerAndStrengthInfo[]) => {
    let sum = 0;
    // if the team has more than 6 players, then the center and the goalie still always in the water and doesn't sub
    // these two are usually the highest rated players in the team
    // as the input doesn't specify which players play which position we assume, that the two highest rated players
    // play positions that doesn't sub
    if (team.length > 6) {
      const sortedStrength = [];
      for (let i = 0; i < team.length; i++) {
        sortedStrength.push(team[i].strength);
      }
      sortedStrength.sort((a, b) => a - b);
      for (let i = 0; i < 2; i++) {
        let strongPlayer = sortedStrength.pop();
        if (strongPlayer !== undefined) {
          sum += strongPlayer;
        }
      }
      let othersStrength = 0.0;
      sortedStrength.forEach((strength) => {
        othersStrength += strength;
      });
      othersStrength = (othersStrength / (team.length - 2)) * 4;
      return sum + othersStrength;
    }
    team.forEach((playerInfo) => {
      sum += playerInfo.strength;
    });
    if (team.length < 6) {
      // Probably a 5v4 or something. No subs in the game so we don't account for that
      return sum;
    }
    return (sum / team.length) * 6;
  };

  const fetchStrengthValues = async () => {
    try {
      let sv = await new StrengthValuesAPI().getLatestStrengthValuesForPlayers(
        props.trainingData.players,
        new Date(props.trainingData.date),
      );
      sv ? setStrengthValues(sv) : console.log("could not set strength values");
    } catch (error) {
      throw new Error(`could not fetch strength values: ${error}`);
    }
  };

  const generateStrengthValueUpdaterProp = () => {
    let blackTeamPlayers: PlayerAndStrengthInfo[] = [];
    let whiteTeamPlayers: PlayerAndStrengthInfo[] = [];
    let blackTeamScore = props.trainingData.black_score;
    let whiteTeamScore = props.trainingData.white_score;

    props.trainingData.black_team.forEach((playerId) => {
      blackTeamPlayers.push({
        id: playerId,
        name: `${props.idNameMap[playerId].lname} ${props.idNameMap[playerId].fname}`,
        strength: strengthValues[playerId],
      });
    });
    props.trainingData.white_team.forEach((playerId) => {
      whiteTeamPlayers.push({
        id: playerId,
        name: `${props.idNameMap[playerId].lname} ${props.idNameMap[playerId].fname}`,
        strength: strengthValues[playerId],
      });
    });

    return {
      blackTeamPlayers: blackTeamPlayers,
      whiteTeamPlayers: whiteTeamPlayers,
      blackTeamScore: blackTeamScore,
      whiteTeamScore: whiteTeamScore,
    };
  };

  const sendNewValues = async (
    blackTeamInfo: PlayerAndStrengthInfoWithValues[],
    whiteTeamInfo: PlayerAndStrengthInfoWithValues[],
  ) => {
    let newValues: { player_id: number; strength_value: number }[] = [];
    blackTeamInfo.forEach((playerInfo) => {
      newValues.push({
        player_id: playerInfo.id,
        strength_value: playerInfo.strengthAfter,
      });
    });
    whiteTeamInfo.forEach((playerInfo) => {
      newValues.push({
        player_id: playerInfo.id,
        strength_value: playerInfo.strengthAfter,
      });
    });
    let payload: UpsertStrengthValuesRequest = {
      date: props.trainingData.date,
      new_values: newValues,
    };

    try {
      await new StrengthValuesAPI().update(payload);
      navigate(`/trainings/${props.trainingData.id}`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStrengthValues()
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        setError(err);
      });
    // eslint-disable-next-line
  }, []);

  const svinfo = generateStrengthValueUpdaterProp();
  const whiteTeamInfo = getUpdatedStrengthScoreForTeam(
    svinfo.whiteTeamPlayers,
    calculateModification(svinfo),
  );
  const blackTeamInfo = getUpdatedStrengthScoreForTeam(
    svinfo.blackTeamPlayers,
    -calculateModification(svinfo),
  );

  if (error) {
    return <h1>Nem sikerült betölteni az oldalt</h1>;
  }

  if (!isLoaded) {
    return <Loader />;
  }


  return (
    <>
      <Table
        withTableBorder
        withRowBorders
        withColumnBorders
        style={{ margin: "1rem" }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Th>Fehér átlagerősség</Table.Th>
            <Table.Td>{getTeamStrength(svinfo.whiteTeamPlayers).toFixed(2)}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Fekete átlagerősség</Table.Th>
            <Table.Td>{getTeamStrength(svinfo.blackTeamPlayers).toFixed(2)}</Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Table
        withRowBorders
        withTableBorder
        withColumnBorders
        style={{ margin: "1rem" }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Név</Table.Th>
            <Table.Th>Előtte</Table.Th>
            <Table.Th>Utána</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {whiteTeamInfo.map((playerStrengthInfo) => {
            return (
              <Table.Tr key={playerStrengthInfo.id}>
                <Table.Td>{playerStrengthInfo.name}</Table.Td>
                <Table.Td>{playerStrengthInfo.strengthBefore.toFixed(2)}</Table.Td>
                <Table.Td>{playerStrengthInfo.strengthAfter.toFixed(2)}</Table.Td>
              </Table.Tr>
            );
          })}
          {blackTeamInfo.map((playerStrengthInfo) => {
            return (
              <Table.Tr
                key={playerStrengthInfo.id}
                style={{ backgroundColor: "black", color: "white" }}
              >
                <Table.Td>{playerStrengthInfo.name}</Table.Td>
                <Table.Td>{playerStrengthInfo.strengthBefore.toFixed(2)}</Table.Td>
                <Table.Td>{playerStrengthInfo.strengthAfter.toFixed(2)}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <Button
        color="green"
        w={150}
        onClick={() => sendNewValues(whiteTeamInfo, blackTeamInfo)}
      >
        Frissítés
      </Button>
    </>
  );
};
