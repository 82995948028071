import { Button, ButtonProps } from "@mantine/core";
import { AntonText } from "../../util/text";
import { IoIosNotificationsOff } from "react-icons/io";

type UnregisterServiceWorkerButtonProps = {
  setRegistrationState: (state: boolean) => void;
} & ButtonProps;

export const UnregisterServiceWorkerButton = (
  props: UnregisterServiceWorkerButtonProps,
) => {
  const unregiserServiceWorker = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      console.log("removing SW registration");
      await registration.unregister();
      console.log("SW registration successfully removed");
      props.setRegistrationState(false);
    }
  };
  return (
    <Button
      leftSection={<IoIosNotificationsOff />}
      onClick={async () => {
        await unregiserServiceWorker();
      }}
      w={300}
    >
      {AntonText("Értesítések kikapcsolása", 700)}
    </Button>
  );
};
