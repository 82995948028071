import React from 'react';
import { Image, Divider, Text, List, Container } from '@mantine/core';

export const TrainingMaterialHorns = () => {
  const getLabelText = (label: string) => {
    return (
      <Text fz="xl">{label}</Text>
    )
  }

  return (
    <>
      <Container>
        <Text ta="left">
          <Divider my="sm" label={getLabelText("Háttér")} labelPosition='center' />
          A "szarv" szabadot a kapu előtt (5-7m a hátfaltól) használhatja a támadó csapat. A felállás és a játékosok egyéni feladatköre aránylag egyszerű, de mégis nagyon hatékony megfelelő kivitelezés mellett. A kapu előtti szituációkban a hangsúly sokszor azon van, hogy melyik csapat tud több embert a lejuttatni a medence aljára a kapu elé.
          Ennek több oka is van:
          <List withPadding>
            <List.Item>Játékmegállítás után folytatdódik a játék, így mindkét csapat játékosai tudnak cserélni és pihenni kicsit, emiatt biztosan 4-6 ember sokáig lent lesz a szabad után.</List.Item>
            <List.Item>Mindkét csapatnak nagyon konkrét célja van (ellentétben a középpálya környékén lévő játék során): a támadóknak a kapu, a védőknék pedig a "minél messzebb a kaputól."</List.Item>
            <List.Item>Várhatóan a korong keveset fog mozogni és az számít, hogy melyik fél tudja majd egyéni és csapat szinten kontrollálni a korongot. Előbbihez elengedhetetlen a saját testünk megfelelő pozícionálása (lent legyünk a medence alján) a korongkezelés mellett, utóbbinál pedig a csapattagok megfelelő elhelyezése a cél.</List.Item>
          </List>
          Ezek miatt a legtöbb kapu előtti szabadlövés esetén azt fogjuk látni, hogy kevés a konkrét terv arra vonatkozólag, hogy ki kinek passzol, hiszen a kis terület és a sok ember miatt reálisan ilyesmivel tervezni esélytelen.
          Ehelyett a támadó csapat megpróbál majd egy területet megcélozni és aköré rendezni a játékosait, a védő csapat pedig ki akarja majd juttatni egy előre megbeszélt módon a korongot valamelyik falhoz.
          <Divider my="sm" label={getLabelText("Felállás")} labelPosition='center' />
          A képen kékkel felrajzolva láthatjuk a támadó csapatot egy standard 2-3-1 felállással játszó védő csapat ellen.
          <Image
            src="https://uwh-webpage-pictures.s3.amazonaws.com/oktatas/horns_1.png"
            alt="Horns pre flick"
            style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', display: 'block', maxWidth: '600px' }}
          />
          A támadó csapat két elsőjét oldalról közrefogja a két szélső, egy kicsit (~30-40cm) előrébb tolódva. A szabadot a leghátsó és középső kettős bármelyike végezheti. A nem lövő félnek csak biztosító szerepe van a szitációban, hogy a kontrákat lelassítsa, amíg a csapat visszarendeződik.<br />
          A csapat célja, hogy a képen fekete körrel jelölt területre bejuttassa a korongot, majd csapatként előre haladva a kapuba tolja azt.<br />
          Ebben a szitációban nem az számít, hogy éppen melyik támadó ér a koronghoz, a hangsúly azon van, hogy a csapat <i>egy kézként</ i> tudja kontrollálni azt.
          <Image
            src="https://uwh-webpage-pictures.s3.amazonaws.com/oktatas/horns_2.png"
            alt="Horns post flick"
            style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', display: 'block', maxWidth: '600px' }}
          />
          Ahogy halad előre a korong a csapattagok megpróbálják a formációt (4 emberes körcikkely + támogató ember mögötte) fenntartani és az ellenfél embereit kiszorítani a kulcspozíciókból, hogy a védekezés még nehezebb legyen. Fontos, hogy a korong <b>ne haladjon túl gyorsan a kapu felé</b> (ergo le lőjje rá túl messziről egy támadó játékos), mert ekkor lehetőséget kap a védő csapat, hogy oldalra kivigye azt, mielőtt az adott szélső le tudná zárni a kiutat.
          <Divider my="sm" label={getLabelText("Pozíciók célja")} labelPosition='center' />
          <b>Elsők</b><br />
          <i>Lövés előtt</i>
          <List type="ordered">
            <List.Item>A kapu közepét és a korongot összekötő vonal mentén álljanak fel egymás mellé.</List.Item>
            <List.Item>A medence alján legyenek, ne félmagasan. Fontos, hogy az ellenfél játékosai ne tudjanak a támadók alá beférkőzni.</List.Item>
            <List.Item>Ne legyenek túl "mélyen", messze a korongtól, a válluk vonala ne nagyon lépje át az ellenfél támadóinak a vállvonalát.</List.Item>
            <List.Item>Legyenek szorosan egymás mellett, max. 30-40cm hely legyen köztük.</List.Item>
          </List>
          <br />
          <i>Ha a korong bejutott a körbe</i><br />
          Cél, hogy a korong a majdnem nyújtott kezük magasságában legyen, de ne messzebb, mivel így tudják a legnagyobb kontrollt tartani felette. Ha a pozíció elhagyása nélkül elérik a korongot, akkor azt minél gyorsabban vissza kell mozgatniuk ebbe a pozícióba: pl. ha becsúszik egyikük hasa alá, akkor forgolódás nélkül az adott játékos megpróbálja visszahúzni azt kettőjük elé.<br />
          Ha nagyon oldalra vagy hátra csúszna a korong, akkor megvárják, hogy a szélsők vagy a szabadot lövő játékos visszajuttassa azt eléjük.<br />
          Fontos, hogy a félkör megmaradjon, mert csapat szinten csak így tudjuk a korongot és a területet uralni.<br />
          <br />
          <b>Szélsők</b><br />
          <i>Lövés előtt</i>
          <List type="ordered">
            <List.Item>Az elsők mellé álljanak két oldalról úgy, hogy a mellkasuk magasságában legyen kb. a támadók maszkja.</List.Item>
            <List.Item>Legyenek szorosan az elsők mellett, max. 20-30cm hely legyen köztük.</List.Item>
            <List.Item>A medence alján legyenek, ne félmagasan. Fontos, hogy az ellenfél játékosai ne tudjanak a támadók alá beférkőzni.</List.Item>
            <List.Item>Figyeljék meg az ellenfél szélsőinek a pozícióját, mivel ideális esetben a korong előrehaladásakor azok belső (korongfelöli) oldalára kell majd kerülniük.</List.Item>
          </List>
          <br />
          <i>Ha a korong bejutott a körbe</i><br />
          A feladatuk, hogy a korongot az elsők előtt tartsák, ha kicsúszna oldalra, akkor kis pöccintéssel/tolással visszajuttassák azt az elsők elé. <br />
          Amikor már a kapuhoz nagyon közel került a csapat, akkor egyre nagyobb figyelmet kell szentelniük annak, hogy a védő csapat ne tudja a korongot a hátfal mentén valamelyik oldalra kijuttatni. <br />
          Nagyon fontos a precíz helyezkedés: nem szabad túl előre helyezkedniük, mivel ekkor a lábuk felé az ellenfél ki tudja vinni a körből a korongot, de túl hátul sem, mivel akkor egy kicsit túl előre csúsztatott korong könnyen ki tud jutni oldalra anélkül, hogy esélye lenne az adott oldali szélsőnek ezt levadászni/megakadályozni.<br />
          <br />
          <b>Középső / Leghátsó</b><br />
          <i>Lövés előtt</i>
          <List type="ordered">
            <List.Item>Próbálja megfigyelni, hogy a két elsőjének várhatóan melyik oldalán úsznak majd el az ellenfél kitámadó emberei, tervezze el, hogy hogyan tudja a korongot bejuttatni*.</List.Item>
          </List>
          <br />
          <i>Ha a korong bejutott a körbe</i><br />
          Kísérje hátulró az akciót, és ha kicsúszna a két első mögé a korong, akkor amilyen gyorsan csak lehet jutassa azt vissza az elsők elé a körbe.<br />
          <br />
          <i>*Általános szabály, hogy a két első három "csatornát" alakít ki: 1-1 a kettőjük fal felöli oldalán, 1 pedig közöttük. Kiúszáskor az ellenfél támadó általában ezeket választják majd. Amennyiben az ellenfélnek 2 kiúszó embere van, 1 csatorna üresen fog maradni (ahol nem úszik ki támadó). A korongot jó eséllyel könnyebben lehet ezen a csatornán bejuttatni az elsőknek, ha időben felismeri a szabadot végző játékos a szituációt.</i>
        </Text>
      </Container>
    </>
  )
}
