import ky from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { NEW_API_URL as API_URL, NEW_API_URL } from "./Config";
import {
  CreateHockeyEventRequest,
  GenericMessage,
  GetHockeyEventResponse,
  GetHockeyEventsResponse,
} from "./Descriptors";
import { getToken } from "./Login";

export class HockeyEventsClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getAll = async (): Promise<GetHockeyEventsResponse> => {
    return await this.api.get(`${API_URL}/hockey-events`).json();
  };

  get = async (id: string): Promise<GetHockeyEventResponse> => {
    const fullUrl = `${API_URL}/hockey-events/${id}`;
    return await this.api.get(fullUrl).json();
  };

  createNew = async (
    event: CreateHockeyEventRequest,
  ): Promise<GetHockeyEventResponse> => {
    return await this.api
      .post(`${API_URL}/hockey-events`, { json: event })
      .json();
  };

  delete = async (id: string): Promise<GenericMessage> => {
    return await this.api.delete(`${NEW_API_URL}/hockey-events/${id}`).json();
  };

  update = async (
    id: string,
    event: CreateHockeyEventRequest,
  ): Promise<GenericMessage> => {
    return await this.api
      .put(`${NEW_API_URL}/hockey-events/${id}`, { json: event })
      .json();
  };
}
