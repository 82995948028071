import { Container, useMantineTheme } from "@mantine/core";
import { Outlet } from "react-router-dom";

export const HockeyEventsLayout = () => {
  const theme = useMantineTheme();

  return (
    <>
      <Container my={20} style={{ fontFamily: theme.fontFamily }}>
        <Outlet />
      </Container>
    </>
  );
};
