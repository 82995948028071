import ky, { Options } from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { NEW_API_URL } from "./Config";
import { ActivityRefetchRequest, GenericMessage, GetListActivitiesForAthleteResponse, MarkActivityAsExcludedFromChallengeResquest, WeeklyActivityReportsResponse } from "./Descriptors";
import { getToken } from "./Login";

export class WeeklyTrainingStatsClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  weeklyActivityReports = async (week: number): Promise<WeeklyActivityReportsResponse> => {
    const options: Options = {
      searchParams: {
        week,
      }
    }
    return await this.api.get(`${NEW_API_URL}/challenge/weekly-report`, options).json();
  };

  activitiesForAthleteForPeriod = async (week: number, stravaAthleteId: number): Promise<GetListActivitiesForAthleteResponse> => {
    const options: Options = {
      searchParams: {
        week,
        stravaAthleteId
      }
    }
    return await this.api.get(`${NEW_API_URL}/challenge/list-activities`, options).json();
  };

  markActivityAsExcludedFromChallenges = async (stravaActivityId: number): Promise<GenericMessage> => {
    const payload: MarkActivityAsExcludedFromChallengeResquest = {
      challenge_id: 1,
      strava_activity_id: stravaActivityId
    }
    const url = `${NEW_API_URL}/challenge/mark-activity-as-excluded`
    return await this.api.post(url, { json: payload }).json();
  }

  markActivityAsIncludedInChallenges = async (stravaActivityId: number): Promise<GenericMessage> => {
    const payload: MarkActivityAsExcludedFromChallengeResquest = {
      challenge_id: 1,
      strava_activity_id: stravaActivityId
    }
    const url = `${NEW_API_URL}/challenge/mark-activity-as-included`
    return await this.api.post(url, { json: payload }).json();
  }

  forceActivityRefetch = async (stravaActivityId: number): Promise<GenericMessage> => {
    const payload: ActivityRefetchRequest = {
      strava_activity_id: stravaActivityId
    }
    const url = `${NEW_API_URL}/activities/force-refetch`
    return await this.api.post(url, { json: payload }).json();
  }
}
