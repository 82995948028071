import { Button, Group, Loader, Modal, Paper, Text } from "@mantine/core";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HockeyEventsClient } from "../api/HockeyEvent";
import { useHockeyEvents } from "../hooks/useHockeyEvent";
import { formatDate } from "../util/naming";

type HockeyEventSingleProps = {
  eventId: string;
};

type DeleteHockeyEventModalProps = {
  eventId: String;
  opened: boolean;
  setOpened: (opened: boolean) => void;
};

const DeleteHockeyEventModal = (props: DeleteHockeyEventModalProps) => {
  const { eventId } = props;
  const navigate = useNavigate();
  const hockeyEventsClient = new HockeyEventsClient();

  const onDeleteHockeyEvent = async (id: String | string | undefined) => {
    if (!id) {
      throw new Error("No id");
    }
    await hockeyEventsClient.delete(id.toString());
    navigate("/events");
  };

  return (
    <>
      <Modal
        opened={props.opened}
        onClose={() => {
          props.setOpened(false);
        }}
        title="Esemény törlése"
      >
        <Text>Biztosan törölni szeretnéd az eseményt?</Text>
        <Group justify="right">
          <Button
            style={{ marginTop: "1rem" }}
            onClick={() => {
              props.setOpened(false);
            }}
          >
            Mégsem
          </Button>
          <Button
            style={{ marginTop: "1rem" }}
            variant="danger"
            onClick={() => {
              onDeleteHockeyEvent(eventId);
            }}
          >
            Törlés
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export const HockeyEventDetails: React.FC = () => {
  const { eventId } = useParams<HockeyEventSingleProps>();
  const [opened, setOpened] = useState(false);
  const { useHockeyEvent } = useHockeyEvents();
  const { data: eventData, isLoading, error } = useHockeyEvent(eventId!);
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <DeleteHockeyEventModal
        eventId={eventId!}
        opened={opened}
        setOpened={setOpened}
      />
      <Paper shadow="xl" mt={"1rem"} pt={"1rem"}>
        <div>{formatDate(eventData?.data.date)}</div>
        <div>{eventData?.data.title}</div>
        <div>{eventData?.data.type}</div>
        <div>{eventData?.data.description}</div>
        <br />
      </Paper>
      <Group justify="right">
        <Button
          style={{
            marginTop: "1rem",
            marginLeft: "auto",
          }}
          variant="primary"
          onClick={() => {
            navigate(`/events/${eventId}/edit`);
          }}
        >
          Szerkesztés
        </Button>
        <Button
          style={{
            marginTop: "1rem",
          }}
          variant="danger"
          onClick={() => {
            setOpened(true);
          }}
        >
          Törlés
        </Button>
      </Group>
    </>
  );
};
