import { useQuery } from "react-query";
import { GetClubsResponse } from "../api/Descriptors";
import { ClubsClient } from "../api/Club";

const clubsClient = new ClubsClient()

export const useClubs = () => {
  const useAllClubs = () => useQuery<GetClubsResponse, Error>({
    queryKey: "clubs",
    queryFn: () => clubsClient.getAll()
  })

  return {
    useAllClubs
  }
}
