import {
  ActionIcon,
  Button,
  ButtonProps,
  Flex,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  Select,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { GrEdit } from "react-icons/gr";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { ClubsClient } from "../../api/Club";
import { GetClubsResponse, GetUserResponse, User } from "../../api/Descriptors";
import { UsersClient } from "../../api/Users";
import { useUser } from "../../context/UserContext";
import { usePlayers } from "../../hooks/usePlayers";
import { useUsers } from "../../hooks/useUsers";
import styles from "./single-user.module.css";

const translateRole = (role: "admin" | "club_manager" | string) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "club_manager":
      return "Klub vezető";
    default:
      return role;
  }
};

type Params = {
  userId: string;
};

type OwnBlueButtonProps = {
  onClick: () => void;
  text: string;
} & ButtonProps;

const OwnBlueButton = (props: OwnBlueButtonProps) => {
  const { text, onClick } = props;

  return (
    <Button
      {...props}
      className={styles["blue-button"]}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </Button>
  );
};

type EditUserModalProps = {
  onClose: () => void;
  opened: boolean;
  userData: User;
};

const EditUserModal = (props: EditUserModalProps) => {
  const { useAllPlayers } = usePlayers();
  const { useUpdateUser, useAddRole, useRoles } = useUsers();
  const { onClose, opened, userData } = props;
  const { mutateAsync: updateUserData } = useUpdateUser(userData.id);
  const { mutateAsync: addRoleToUser } = useAddRole();
  const clubsClient = new ClubsClient();
  const [playerId, setPlayerId] = useState(
    userData.player_id?.toString() ?? null,
  );
  const [clubId, setClubId] = useState(userData.club_id?.toString() ?? null);
  const { user, error, loading } = useUser();
  const [role, setRole] = useState(user?.roles.at(0) ?? null);

  const { data: roles, error: errorR, isLoading: isLoadingR } = useRoles();
  const {
    data: clubs,
    error: errorC,
    isLoading: isLoadingC,
  } = useQuery("clubs", clubsClient.getAll);
  const {
    data: players,
    error: errorP,
    isLoading: isLoadingP,
  } = useAllPlayers();

  const clubsSelectData = clubs
    ? clubs.data.map((c) => {
        return {
          label: c.name,
          value: c.id.toString(),
        };
      })
    : [];

  const playersIdSelectData = players
    ? players.map((p) => {
        return {
          label: `${p.id} (${p.lname} ${p.fname})`,
          value: `${p.id}`,
        };
      })
    : [];

  const handleOnSave = async () => {
    const convertedPlayerId = playerId ? parseInt(playerId) : undefined;
    const convertedClubId = clubId ? parseInt(clubId) : undefined;

    await updateUserData({
      club_id: convertedClubId,
      player_id: convertedPlayerId,
    });

    if (role) {
      await addRoleToUser({ user_id: userData.id, role: role });
    }
    onClose();
  };

  if (errorC || errorP || errorR || error) {
    return <h2>Hiba az adatok betöltésekor</h2>;
  }

  if (isLoadingC || isLoadingP || isLoadingR || loading) {
    return <Loader />;
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text size={"xl"} td={"underline"}>
          Adatok szerkesztése
        </Text>
      }
    >
      <Grid>
        <Grid.Col span={6}>
          <Text>Játékos azonosító</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            data={playersIdSelectData ?? []}
            value={playerId}
            onChange={setPlayerId}
            searchable
            allowDeselect
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Text>Klub</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            value={clubId}
            onChange={setClubId}
            data={clubsSelectData ?? []}
            searchable
            allowDeselect
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Text>Szerep</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            value={role}
            onChange={setRole}
            data={
              roles
                ? roles.data.map((r) => {
                    return {
                      label: translateRole(r),
                      value: r,
                    };
                  })
                : []
            }
            searchable
            allowDeselect
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Group>
            <OwnBlueButton
              style={{ marginLeft: "auto", marginTop: "1rem" }}
              text={"Mentés"}
              onClick={() => {
                handleOnSave();
              }}
            />
          </Group>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export const UserDetailed = () => {
  const { userId } = useParams<Params>();
  const userClient = new UsersClient();
  const clubsClient = new ClubsClient();

  const [opened, { open, close }] = useDisclosure();

  const { isLoading, error, data } = useQuery<GetUserResponse>(
    ["users", userId],
    () => userClient.getUser(parseInt(userId!)),
  );

  const {
    isLoading: clubsLoading,
    error: clubsError,
    data: clubData,
  } = useQuery<GetClubsResponse>("clubs", clubsClient.getAll);

  const user = data?.data;

  if (error || clubsError) {
    return <h2>Nem sikerült betölteni a felhasznói adatokat</h2>;
  }

  if (isLoading || clubsLoading) {
    return <Loader />;
  }

  const textEntry = (title: string, content: string | number | undefined) => {
    return (
      <Group style={{ padding: "0.5rem" }}>
        <Text fw={900} style={{ width: 200, textAlign: "left" }}>
          {title}
        </Text>
        <Text style={{ textAlign: "right", marginLeft: "auto" }}>
          {content}
        </Text>
      </Group>
    );
  };

  return (
    <>
      <EditUserModal opened={opened} onClose={close} userData={data!.data} />
      <Flex style={{ flexDirection: "column" }}>
        <Paper
          style={{
            padding: "2rem",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "0.5rem",
          }}
          shadow={"xl"}
        >
          <Group>
            <Text
              size={"xl"}
              fw={900}
              style={{ textAlign: "left", marginBottom: "1rem" }}
            >
              Alapadatok
            </Text>
            <ActionIcon style={{ marginLeft: "auto" }} onClick={() => open()}>
              <GrEdit />
            </ActionIcon>
          </Group>
          {textEntry(
            "Név",
            `${user?.strava_last_name} ${user?.strava_first_name}`,
          )}
          {textEntry("Strava azonosító", user?.strava_id)}
          {textEntry("Játékos azonosító", user?.player_id)}
          {textEntry("Felhasználónév", user?.username)}
          {textEntry(
            "Klub",
            clubData?.data.find((c) => c.id === user?.club_id)?.name,
          )}
        </Paper>
      </Flex>
    </>
  );
};
