import React from "react";
import { Loader, Table } from "@mantine/core";
import { getPositionByValue } from "../../util/position";
import { usePlayers } from "../../hooks/usePlayers";

type PlayerInfoTableProps = {
  playerId: string;
};

const convertStatus = (status: string | undefined) => {
  if (
    status === undefined ||
    status.toLowerCase() === "inactive" ||
    status.toLowerCase() === "inaktív"
  ) {
    return "Inaktív";
  }
  return "Aktív";
};

export const PlayerInfoTable: React.FC<PlayerInfoTableProps> = (
  props: PlayerInfoTableProps,
) => {
  const { playerId } = props;
  const { usePlayer, useAllPlayers, usePairing } = usePlayers();

  const {
    isLoading: isLoadingPlayer,
    error: errorPlayer,
    data: player,
  } = usePlayer(playerId);
  const {
    isLoading: isLoadingPairing,
    error: errorPairing,
    data: pairing,
  } = usePairing(playerId);
  const {
    isLoading: isLoadingPlayers,
    error: errorPlayers,
    data: players,
  } = useAllPlayers();

  if (isLoadingPlayer || isLoadingPairing || isLoadingPlayers) {
    return <Loader />;
  }

  if (errorPlayer || errorPairing || errorPlayers) {
    console.error(errorPlayer);
    return (
      <div>
        Nem sikerült lekérdezni a <b>{props.playerId}</b> számú játékost.
        Biztosan jó ID-t akarsz lekérdezni?
      </div>
    );
  }

  const getPlayersByIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    const playerNames = ids.map((id) => {
      const player = players?.find((p) => p.id === Number(id));
      if (player) {
        return player.lname + " " + player.fname;
      }
      return `Ismeretlen (${id})`;
    });
    return playerNames;
  };

  return (
    <>
      <Table striped withRowBorders withTableBorder withColumnBorders>
        <Table.Tbody>
          <Table.Tr>
            <Table.Th>Név</Table.Th>
            <Table.Td>
              {player?.lname} {player?.fname}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Erősség</Table.Th>
            <Table.Td>{player?.training_strength}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Pozíció 1.</Table.Th>
            <Table.Td>{getPositionByValue(player?.primary_position)?.label}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Pozíció 2.</Table.Th>
            <Table.Td>{getPositionByValue(player?.secondary_position)?.label}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Státusz</Table.Th>
            <Table.Td>{convertStatus(player?.status)}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Leggyakoribb csapattárs</Table.Th>
            {pairing?.most_paired ? (
              <Table.Td>
                {pairing?.most_paired.value} alkalommal:{" "}
                {getPlayersByIds(pairing?.most_paired.player_ids).join(", ")}{" "}
              </Table.Td>
            ) : (
              <Table.Td>Nincs adat</Table.Td>
            )}
          </Table.Tr>
          <Table.Tr>
            <Table.Th>Legritkább csapattárs</Table.Th>
            {pairing?.least_paired ? (
              <Table.Td>
                {pairing?.least_paired.value} alkalommal:{" "}
                {getPlayersByIds(pairing?.least_paired.player_ids).join(", ")}
              </Table.Td>
            ) : (
              <Table.Td>Nincs adat</Table.Td>
            )}
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  );
};
