import ky, { Options } from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { NEW_API_URL } from "./Config";
import {
  CreateChallengePeriodRequest,
  ChallengePointsForPeriodResponse,
  CreateChallengePeriodResponse,
  ChallengePeriodsResponse,
} from "./Descriptors";
import { getToken } from "./Login";

export class ChallengeClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getChallengePoints = async (
    periodId: number
  ): Promise<ChallengePointsForPeriodResponse> => {
    const options: Options = {
      searchParams: {
        period_id: periodId,
      },
    };
    return await this.api
      .get(`${NEW_API_URL}/challenge-points`, options)
      .json();
  };

  createChallengePeriod = async (
    args: CreateChallengePeriodRequest
  ): Promise<CreateChallengePeriodResponse> => {
    return await this.api
      .put(`${NEW_API_URL}/challenge-periods`, { json: args })
      .json();
  };

  listChallengePeriods = async (): Promise<ChallengePeriodsResponse> => {
    return await this.api.get(`${NEW_API_URL}/challenge-periods`).json();
  };
}
