import { Container } from "@mantine/core"
import { Outlet } from "react-router-dom"

export const MaterialsLayout = () => {
    return (
        <>
            <Container my={20}>
                <Outlet />
            </Container>
        </>
    )
}