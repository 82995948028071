import { Button, Group, Loader } from "@mantine/core";
import { Suspense, useEffect, useMemo } from "react";
import { RiArrowLeftDoubleLine, RiArrowRightDoubleLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { TrainingData } from "../../api/Descriptors";
import { TrainingClient } from "../../api/Trainings";
import { renderDate } from "../../util/naming";
import { OrderableTable } from "../base/orderable-table";

export type AllTrainingsProps = {};
export type SearchParamProps = {
  pageNumber: string;
  pageSize: string;
};

export const AllTrainingsTable = (props: AllTrainingsProps) => {
  const trainingApi = new TrainingClient();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        pageSize: 10,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageNumber = () => {
    return parseInt(searchParams.get("pageNumber")!);
  };

  const getPageSize = () => {
    return parseInt(searchParams.get("pageSize")!);
  };

  const nextPage = () => {
    setSearchParams((prev) => {
      return {
        ...prev,
        pageSize: getPageSize(),
        pageNumber: getPageNumber() + 1,
      };
    });
  };
  const prevPage = () => {
    setSearchParams((prev) => {
      return {
        ...prev,
        pageSize: getPageSize(),
        pageNumber: getPageNumber() - 1,
      };
    });
  };

  const { isLoading, error, data } = useQuery<TrainingData[]>({
    queryKey: ["all-trainings", getPageNumber(), getPageSize()],
    queryFn: () => {
      const data = trainingApi.getAll({
        pageNumber: getPageNumber(),
        pageSize: getPageSize(),
      });

      data.then((d) => {
        d.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
      });

      return data;
    },
    suspense: true,
  });

  const columns = useMemo<
    Column<{ id: number; date: string; participants: number }>[]
  >(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Dátum",
        accessor: "date",
      },
      {
        Header: "Résztvevők száma",
        accessor: "participants",
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((t) => {
      return {
        id: t.id,
        date: renderDate(t.date),
        participants: t.players.length,
      };
    });
  }, [data]);
  const tableInstance = useTable({ columns, data: tableData }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (error) {
    return <h2>Sajnos nem sikerült betölteni az adatokat</h2>;
  }
  if (isLoading) {
    return <Loader my={20} />;
  }

  const linkAccessor = {
    accessor: "id",
    baseUrl: "/trainings",
  };
  return (
    <Suspense fallback={<Loader my={20} />}>
      <OrderableTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        linkAccessor={linkAccessor}
      />
      <Group style={{ marginTop: "1rem" }}>
        <Button
          style={{
            marginLeft: "1rem",
            minWidth: 140,
          }}
          onClick={() => {
            prevPage();
          }}
          disabled={getPageNumber() <= 1}
          leftSection={<RiArrowLeftDoubleLine />}
        >
          Előző
        </Button>
        <Button
          style={{
            marginRight: "1rem",
            marginLeft: "auto",
            minWidth: 140,
          }}
          onClick={() => {
            nextPage();
          }}
          rightSection={<RiArrowRightDoubleLine />}
        >
          Következő
        </Button>
      </Group>
    </Suspense>
  );
};
