import React from "react";
import { AuthenticationForm } from "../components/Login";
import { useSearchParams } from "react-router-dom";

export const LoginPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") ?? undefined;

  return <AuthenticationForm token={token} />;
};
