import { Button, Container } from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlayerInfoTable } from "../../components/player/table-info";

type SinglePlayerProps = {
    playerId: string
}

export const SinglePlayer = () => {
    const { playerId } = useParams<SinglePlayerProps>()
    const navigate = useNavigate()

    if (!playerId) {
        return <div>{`A játékos (id=${playerId}) nem található`}</div>
    }

    const getUpdatePath = () => {
        return `/players/${playerId}/update`
    }

    return (
        <>
            <Container>
                <PlayerInfoTable playerId={playerId} />
                <Button style={{ marginTop: "10px" }} onClick={() => navigate(getUpdatePath())}>
                    Szerkesztés
                </Button>
            </Container>
        </>
    )
}