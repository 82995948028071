import ky from 'ky';
import { API_URL } from './Config';
import { LoginRequest, LoginResponse } from './Descriptors';


const baseUrl = `${API_URL}/users/login/`;

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
}

export const getToken = () => {
  return localStorage.getItem('token');
}

export const login = async (payload: LoginRequest): Promise<LoginResponse> => {
  // allow cors
  const data: LoginResponse = await ky
    .post(baseUrl, { json: payload })
    .json()
  setToken(data.token);
  return data;
}

export const logout = () => {
  localStorage.removeItem('token');
}
