import { ActionIcon } from "@mantine/core";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

type PlusMinusButtonProps = {
	doPlus: () => void,
	doMinus: () => void
}

export const PlusMinusButton = (props: PlusMinusButtonProps) => {
	const handlePlusClick = () => {
		props.doPlus()
	}

	const handleMinusClick = () => {
		props.doMinus()
	}

	return (
		<>
			<ActionIcon onClick={handlePlusClick} color="green" size={"lg"}>
				<FaPlusCircle />
			</ActionIcon>
			<ActionIcon onClick={handleMinusClick} color="red" size={"lg"} ml={"1rem"}>
				<FaMinusCircle />
			</ActionIcon>
		</>
	)
}
