import { useEffect, useState } from "react";
import { UnregisterServiceWorkerButton } from "./UnregisterServiceWorkerButton";
import { RegisterServiceWorkerButton } from "./PushNotification";

export const NotificationRegistrationButton = () => {
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    navigator.serviceWorker?.getRegistration().then((registration) => {
      setIsRegistered(!!registration);
    });
  }, []);

  if (isRegistered) {
    return (
      <UnregisterServiceWorkerButton setRegistrationState={setIsRegistered} />
    );
  }
  return <RegisterServiceWorkerButton setRegistrationState={setIsRegistered} />;
};
