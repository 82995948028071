import React from "react";
import { Loader } from "@mantine/core";
import { Column, useSortBy, useTable } from "react-table";
import { OrderableTable } from "../base/orderable-table";
import { usePlayers } from "../../hooks/usePlayers";


export const WinningPercentageTable = () => {
  const { useWinningPercentageStats, useIdNameMap } = usePlayers()

  const { isLoading: isLoadingW, error: errorW, data: winStats, } = useWinningPercentageStats()
  const { isLoading: isLoadingP, error: errorP, data: idNameMap } = useIdNameMap()
  const minNumberOfGames = 20;

  const columns = React.useMemo<
    Column<{
      name: string;
      all: number;
      won: number;
      draw: number;
      percentage: string;
      wonPlusDrawPercentage: string;
    }>[]
  >(
    () => [
      {
        Header: "Név",
        accessor: "name",
      },
      {
        Header: "Összes",
        accessor: "all",
      },
      {
        Header: "Nyert",
        accessor: "won",
      },
      {
        Header: "Döntetlen",
        accessor: "draw",
      },
      {
        Header: "Győzelmi arány",
        accessor: "percentage",
        sortType: "alphanumeric",
      },
      {
        Header: "Győzelem + döntetlen arány",
        accessor: "wonPlusDrawPercentage",
        sortType: "alphanumeric",
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    if (!winStats) {
      return [];
    }

    const getNameFromId = (id: string) => {
      const getName = (nameMap: { fname: string; lname: string }) => {
        return `${nameMap.lname} ${nameMap.fname}`;
      };
      return idNameMap ? getName(idNameMap[id]) : "unknown";
    };

    const makeSureItsNumber = (value: any) => {
      if (typeof value === "number") {
        return value;
      }
      return 0;
    };

    const filtered = Object.entries(winStats).filter(
      ([_id, { all }]) => all > 20
    );

    return filtered.map(([id, { all, won, draw }]) => ({
      name: getNameFromId(id),
      all: makeSureItsNumber(all),
      won: makeSureItsNumber(won),
      draw: makeSureItsNumber(draw),
      percentage: all ? `${((won / all) * 100).toFixed(2).toString()}%` : "0%",
      wonPlusDrawPercentage: all
        ? `${(((won + draw) / all) * 100).toFixed(2).toString()}%`
        : "0%",
    }));
  }, [winStats, idNameMap]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
      },
      useSortBy
    );

  if (isLoadingW || isLoadingP) {
    return <Loader />;
  }

  if ( errorW || errorP) {
    return <div>Error</div>;
  }

  return (
    <OrderableTable
      headerGroups={headerGroups}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      rows={rows}
      prepareRow={prepareRow}
      caption={`A minimum ${minNumberOfGames} mérkőzéssel rendelkező játékosok vannak csak megjelenítve.`}
    />
  );
};
