import { API_URL } from "./Config"
import { GenericMessage, ScorerDataUpdate, ScorerEntry } from "./Descriptors"
import { Utils } from "./Utils"

export class GoalScorerAPI {
	util: Utils
	goalScorersUrl: string

	constructor() {
		this.goalScorersUrl = `${API_URL}/goal-scorers/`
		this.util = new Utils()
	}

	async update(payload: ScorerDataUpdate) {
		try {
			return await this.util.putApiCallWithPayload<GenericMessage>(this.goalScorersUrl, payload)
		} catch (error) {
			throw error
		}
	}

	async getAll() {
		try {
			return await this.util.apiCall<ScorerEntry[]>(this.goalScorersUrl)
		} catch (error) {
			throw error
		}
	}
}