import { Container } from "@mantine/core";
import React from "react";
import { Outlet } from "react-router-dom";

export const AllPlayers = () => {
    return (
        <>
            <Container my={20}>
                <Outlet />
            </Container>
        </>
    )
}