import { PlusMinusButton } from "./PlusMinusButton";
import { Table, Text } from "@mantine/core";

type GoalInputFieldProps = {
  score: number;
  incrementer: () => void;
  decrementer: () => void;
  name: string;
  color: string;
};

export const GoalInputRows = (props: GoalInputFieldProps) => {
  const incrementer = () => {
    props.incrementer();
  };

  const decrementer = () => {
    props.decrementer();
  };

  const getTextColor = (backgroundColor: string) => {
    switch (backgroundColor) {
      case "black":
        return "white";
      case "white":
        return "black";
      default:
        return "white";
    }
  };

  return (
    <Table.Tr bg={props.color}>
      <Table.Td>
        <Text
          style={{
            backgroundColor: props.color,
            color: getTextColor(props.color),
          }}
        >
          {props.name}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text
          style={{
            backgroundColor: props.color,
            color: getTextColor(props.color),
          }}
        >
          {props.score}
        </Text>
      </Table.Td>
      <Table.Td w={180}>
        <PlusMinusButton doPlus={incrementer} doMinus={decrementer} />
      </Table.Td>
    </Table.Tr>
  );
};
