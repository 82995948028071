import {
  Button,
  Container,
  Loader,
  NumberInput,
  Paper,
  Select,
  TextInput,
} from "@mantine/core";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePlayers } from "../../hooks/usePlayers";
import { usePlayerUpdateForm } from "../../util/forms";
import {
  getPositionByLabel,
  getPositionByValue,
  getPositions,
} from "../../util/position";

type UpdatePlayerInputParams = {
  playerId: string;
};

export const UpdatPlayer: FC<{}> = () => {
  const navigate = useNavigate();

  const { playerId } = useParams<UpdatePlayerInputParams>();
  const pId = parseInt(playerId!);

  const { useUpdatePlayer, usePlayer } = usePlayers();
  const { isLoading, error, data } = usePlayer(pId);
  const { mutateAsync } = useUpdatePlayer(pId!);
  const form = usePlayerUpdateForm({ playerInfo: data });

  const updatePlayer = async () => {
    if (!playerId) {
      return Promise.reject("Player id is not defined");
    }

    const primPos = Number(
      getPositionByLabel(form.values.primaryPosition).value,
    );
    const secPos = Number(
      getPositionByLabel(form.values.secondaryPosition).value,
    );
    const requestData = {
      fname: form.values.fname,
      lname: form.values.lname,
      primary_position: primPos,
      secondary_position: secPos,
      status: form.values.status,
      training_strength: form.values.trainingStrength,
    };
    await mutateAsync(requestData);
    navigate(`/players/${playerId}`);
  };

  if (!playerId) {
    return <div>Player id is not defined</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return (
      <div>
        Nem sikerült lekérdezni a <b>{playerId}</b> számú játékost. Biztosan jó
        ID-t akarsz lekérdezni?
      </div>
    );
  }

  return (
    <>
      <Container size={420} my={30}>
        <Paper withBorder p={30} shadow="md" radius="md">
          <TextInput label="Vezetéknév" {...form.getInputProps("lname")} />
          <TextInput
            label="Keresztnév"
            placeholder="Jakab"
            {...form.getInputProps("fname")}
          />
          <Select
            data={getPositions({ includeUnknown: false }).map((p) => p.label)}
            label="Elsődleges pozíció"
            {...form.getInputProps("primaryPosition")}
            placeholder={getPositionByValue(form.values.primaryPosition).label}
          />
          <Select
            data={getPositions({ includeUnknown: false }).map((p) => p.label)}
            label="Másodlagos pozíció"
            {...form.getInputProps("secondaryPosition")}
            placeholder={
              getPositionByValue(form.values.secondaryPosition).label
            }
          />
          <NumberInput
            label="Erősség"
            placeholder={form.values.trainingStrength.toString()}
            {...form.getInputProps("trainingStrength")}
            step={0.5}
            decimalScale={1}
            max={12.0}
            min={1.0}
          />
          <Select
            data={["Aktív", "Inaktív"]}
            label="Státusz"
            {...form.getInputProps("status")}
            placeholder={form.values.status}
          />
          <Container>
            <Button onClick={updatePlayer} color="green.5" my={20}>
              Mentés
            </Button>
            <Button mx={15} my={20} color="red.5">
              Vissza
            </Button>
          </Container>
        </Paper>
      </Container>
    </>
  );
};
