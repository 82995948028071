import { API_URL } from "./Config"
import { TeamPickerResponse } from "./Descriptors"
import { Utils } from "./Utils"

export class TeamPickerAPI {
	utils: Utils
	teamPickerUrl: string

	constructor() {
		this.utils = new Utils()
		this.teamPickerUrl = `${API_URL}/team-picker/`
	}

	async getNewTeams(payload: number[]) {
		try {
			const response = await this.utils.postApiCallWithPayload(this.teamPickerUrl, payload)
			return response as TeamPickerResponse
		} catch (reason) {
			console.log(reason)
			return {} as TeamPickerResponse
		}
	}
}