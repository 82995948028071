import React from "react";
import { FC } from "react";

export const History: FC<{}> = () => {
  return (
    <div>
      <h1>A sportág rövid története</h1>
      <p>
        A sportot Angliában találta ki Alan Baker 1954-ben, aki az akkor újonnan
        alapított Déltengeri Vízalatti Szövetség (Southsea Sub-Aqua Club) tagja
        volt. A játék eredeti célja az volt, hogy visszatartsák az új tagokat,
        hogy elhagyják a klubot a téli hónapokban, amikor is túl hideg volt a
        tenger vize a búvárkodáshoz. Itt, a Southsea Sub-Aqua Club- ban
        játszotta Baker először a vízihokit búvártársaival, többek között John
        Ventham- mel, Jack Willis- szel és Frank Lilleker- rel.
      </p>
      <p>
        Eredetileg "Octopush"- nak nevezték el a játékot (és Nagy Britannia
        területén még ma is főleg ezen a néven hivatkoznak erre a sportra). A
        szabályok szerint a játékhoz két, 8 fős csapatra (innen az „octo-”), egy
        ütőre emlékeztető kis tolópálcára (innen a „-push”), egy borítás nélküli
        korongra és egy kapura volt szükség.
      </p>
      <p>
        Az első szabályok 1954- ben lettek kipróbálva egy 2 a 2 elleni
        küzdelemben. Ugyanebben az évben már cikk is jelent meg erről az új
        sportról a Neptune- ban, ami a hivatalos kiadványa volt a Britt
        Vízalatti Szövetségnek (British Sub-Aqua Club). Az első klubok közötti
        bajnokság 1955- ben volt. Három csapat vett részt: a Southsea, a
        Brighton és a Bournemouth. A bajnokságot a Southsea nyerte, akik azóta
        is előkelő helyet foglalnak el a britt nemzeti mezőnyben. A játék
        először Kanadába került át, 1962- ben Norm Liebeck által… 10 évvel
        később megalakult a UHABC vízihoki egyesület, amit már az állam is
        támogatott.
      </p>
      <p>
        A későbbi lényeges változtatásoknak köszönhetően (felszerelés,
        csapatlétszám, stb.) egy nemzetközileg elismert és kedvelt sportággá
        nőtte ki mára magát a vízihoki. A legutóbbi, Nagy Britanniában
        megrendezett, 2006- os sheffieldi világbajnokságon 17 országból, 44
        csapat vett részt (és nem képviseltette magát minden vízihoki klubbal
        rendelkező ország).
      </p>
      <h1>A vízihoki Magyarországon</h1>
      <p>
        A sportág 1995 óta létezik Magyarországon. Budapesten, Kecskeméten,
        Szolnokon, Egerben tömegsport jelleggel indult. A budapesti csapatokat a
        Józsefvárosi Vízihoki és Tömegsport Közhasznú Egyesület (JVTKE) keretén
        belül működő Egyszusz Vízihoki Klub koordinálja és látja el szakmai
        segítséggel. Edzőink közül többen évek óta játszanak a francia nemzeti
        bajnokságokban, ezen túl versenybírói szakvizsgákkal is rendelkeznek.
        1998 óta versenyszintre nőtte ki magát a magyar mezőny. Azóta is
        folyamatosan nemzetközi tornákon veszünk részt. Terveink között szerepel
        az utánpótlás nevelés elkezdése 10 éves kortól. Bázisnak az általános
        iskolákat tekintjük.
      </p>
    </div>
  );
};
