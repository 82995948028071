import ky from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { API_URL } from "./Config";
import { getToken } from "./Login";
import { CreatePushSubscription, GenericMessage } from "./Descriptors";

export class NotificationAPI {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  createPushSubscription = async (
    subscriptionInfo: CreatePushSubscription
  ): Promise<GenericMessage> => {
    return await this.api
      .post(`${API_URL}/notifications/register`, { json: subscriptionInfo })
      .json();
  };
}
