import { Container } from "@mantine/core"
import { WinningPercentageTable } from "../components/stats/winning-percentage-table"

export const WinningPercentageView = () => {

    return (
        <>
            <Container>
                <WinningPercentageTable />
            </Container>
        </>
    )
}