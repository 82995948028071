import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ScorerDataUpdate } from "../api/Descriptors";
import { GoalScorerAPI } from "../api/GoalScorer";
import { GoalInputRows } from "../components/GoalInputField";
import { usePlayers } from "../hooks/usePlayers";
import { useTrainings } from "../hooks/useTrainings";
import { Button, Table } from "@mantine/core";

type ScoreInfo = {
  name: string;
  id: number;
  score: number;
  color: string;
};

type ScoreInputParams = {
  trainingId: string;
};

export const ScoreInput = () => {
  const { trainingId } = useParams<ScoreInputParams>();

  if (!trainingId) {
    return <h2>Training id is missing</h2>;
  }

  return <ScoreInputInner trainingId={trainingId} />;
};

type ScoreInputInnerParams = {
  trainingId: string;
};

export const ScoreInputInner = (props: ScoreInputInnerParams) => {
  const { trainingId } = props;
  const navigate = useNavigate();

  const { useTraining } = useTrainings();
  const { data: trainingData } = useTraining(trainingId);

  const { useIdNameMap } = usePlayers();
  const { data: playerIdNameMap } = useIdNameMap();

  const [scores, setScores] = useState<ScoreInfo[]>([]);

  const fetchPresentPlayers = async () => {
    if (!trainingId) {
      console.log("did not receive training id, aborting");
      return;
    }

    try {
      if (!trainingData || !playerIdNameMap) {
        console.log(
          "could not retrieve necessary data (training or player id-name map)",
        );
        return;
      }

      let scoreInfos: ScoreInfo[] = [];
      trainingData.white_team.forEach((playerId) => {
        scoreInfos.push({
          name: `${playerIdNameMap[playerId].lname} ${playerIdNameMap[playerId].fname}`,
          id: playerId,
          score: 0,
          color: "white",
        });
      });
      trainingData.black_team.forEach((playerId) => {
        scoreInfos.push({
          name: `${playerIdNameMap[playerId].lname} ${playerIdNameMap[playerId].fname}`,
          id: playerId,
          score: 0,
          color: "black",
        });
      });

      setScores(scoreInfos);
    } catch (reason) {
      throw reason;
    }
  };

  useEffect(() => {
    fetchPresentPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingData, playerIdNameMap]);

  const changeScore = (id: number, value: number) => {
    setScores((prevScoreState) => {
      let newState = [] as ScoreInfo[];
      prevScoreState.forEach((scoreInfo) => {
        let newInfo = { ...scoreInfo };
        if (newInfo.id === id) {
          const newValue = newInfo.score + value;
          if (newValue >= 0) {
            newInfo.score = newValue;
          }
        }
        newState.push(newInfo);
      });
      return newState;
    });
  };

  const incrementScore = (id: number) => {
    return () => {
      changeScore(id, 1);
    };
  };

  const decrementScore = (id: number) => {
    return () => {
      changeScore(id, -1);
    };
  };

  const updateData = async () => {
    let payload = {
      training_id: trainingId,
      scorers: {} as { [id: number]: number },
    } as ScorerDataUpdate;

    scores.forEach((scoreInfo) => {
      payload.scorers[scoreInfo.id] = scoreInfo.score;
    });

    if (!trainingId) {
      console.log("can not update as trainingId is missing");
      return;
    }

    try {
      await new GoalScorerAPI().update(payload);
      navigate(`/trainings/${trainingId}/strength-values`);
    } catch (reason) {
      alert(`nem sikerült a góllövők listájának a frissítése ${reason}`);
    }
  };

  const cancel = () => {
    navigate(`/trainings/${trainingId}`);
  };

  useEffect(() => {
    fetchPresentPlayers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Table withColumnBorders withTableBorder>
        <Table.Tbody>
          {scores.map((score) => {
            return (
              <GoalInputRows
                key={score.id}
                name={score.name}
                score={score.score}
                color={score.color}
                decrementer={decrementScore(score.id)}
                incrementer={incrementScore(score.id)}
              />
            );
          })}
        </Table.Tbody>
      </Table>
      <Button color="green" onClick={updateData} style={{ margin: "1rem", width: 150 }}>
        Frissítés
      </Button>
      <Button color="red" onClick={cancel} style={{ margin: "1rem", width: 150 }}>
        Mégse
      </Button>
    </>
  );
};
