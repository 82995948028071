import { useQuery } from "react-query";
import { TrainingData } from "../api/Descriptors";
import { TrainingClient } from "../api/Trainings";

const trainingsClient = new TrainingClient();

export const useTrainings = () => {
  // const queryClient = useQueryClient()

  const useTraining = (trainingId: number | string) => {
    if (typeof trainingId === "string") {
      trainingId = parseInt(trainingId);
      if (Number.isNaN(trainingId)) {
        throw new Error(`trainingId ('${trainingId}') is not a number`);
      }
    }

    return useQuery<TrainingData, Error>({
      queryKey: ["trainings", trainingId],
      queryFn: () => trainingsClient.getSingleTraining(trainingId.toString()),
    });
  };

  const useAllTrainings = ({
    pageSize,
    pageNumber,
  }: {
    pageSize: number;
    pageNumber: number;
  }) =>
    useQuery<TrainingData[], Error>({
      queryKey: ["trainings", pageSize, pageNumber],
      queryFn: () => trainingsClient.getAll({ pageSize, pageNumber }),
    });

  return {
    useTraining,
    useAllTrainings,
  };
};
