import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setToken } from "../api/Login";

import {
  Button,
  Container,
  MantineTheme,
  Paper,
  PasswordInput,
  TextInput,
  Title,
} from "@mantine/core";
import { useUser } from "../context/UserContext";
import { StravaAuthorizationButton } from "./auth/StravaAuthorizationButton";

export type AuthenticationFormProps = {
  token?: string;
};

export function AuthenticationForm(props: AuthenticationFormProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();
  const { login } = useUser();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await login({ username, password });
    // redirect to page in url or home page
    searchParams.get("redirectTo")
      ? navigate(searchParams.get("redirectTo") as string)
      : navigate("/");
  };

  if (props.token) {
    setToken(props.token);
    navigate("/");
  }

  return (
    <Container size={420} my={40}>
      <Title
        ta="center"
        style={(theme: MantineTheme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Üdvözöllek!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="Felhasználónév"
          required
          value={username}
          onChange={handleUsernameChange}
        />
        <PasswordInput
          label="Jelszó"
          required
          mt="md"
          onChange={handlePasswordChange}
        />
        {/* <Group position="apart" mt="lg">
                    <Checkbox label="Emlékezz rám" />
                    <Anchor component="button" size="sm">
                        Elfelejtett jelszó
                    </Anchor>
                </Group> */}
        <Button fullWidth mt="xl" onClick={handleSubmit}>
          Bejelentkezés
        </Button>
        <hr />
        <StravaAuthorizationButton
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1rem",
            width: 200,
          }}
        />
      </Paper>
    </Container>
  );
}
