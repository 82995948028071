import { Text } from "@mantine/core";

export const AntonText = (
  text: string,
  fontWeight?: number,
  fontSize?: number,
) => {
  return (
    <Text
      style={{
        fontFamily: "Anton",
        letterSpacing: "0.03rem",
        fontWeight,
        fontSize,
      }}
    >
      {text}
    </Text>
  );
};
