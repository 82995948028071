import { Image, ImageProps } from "@mantine/core";
import { NEW_API_URL } from "../../api/Config";

type StravaAuthorizationButtonProps = {} & ImageProps;

export const StravaAuthorizationButton = (
  props: StravaAuthorizationButtonProps
) => {
  const clientId = 60914;
  const redirectUri = `${NEW_API_URL}/strava-auth-callback`;
  const scope = "activity:read";

  const stravaAuthUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}`;

  return (
    <Image
      {...props}
      onClick={() => {
        window.location.href = stravaAuthUrl;
      }}
      onMouseEnter={() => {
        window.document.body.style.cursor = "pointer";
      }}
      onMouseLeave={() => {
        window.document.body.style.cursor = "default";
      }}
      src={"strava_button.svg"}
    />
  );
};
