import { useMutation, useQuery, useQueryClient } from "react-query";
import { UsersClient } from "../api/Users";
import { AddRoleToUserRequest, AllUsersResponse, GenericMessage, GetRolesResponse, GetUserResponse, UpdateUserRequest, WhoAmIResponse } from "../api/Descriptors";

const usersClient = new UsersClient()

export const useUsers = () => {
  const queryClient = useQueryClient()

  const useAllUsers = useQuery<AllUsersResponse, Error>({
    queryKey: "users",
    queryFn: usersClient.getAllUsers,
  });

  const useWhoAmI = () => useQuery<WhoAmIResponse, Error>({
    queryKey: ["users", "whoami"],
    queryFn: usersClient.getWhoAmI
  })

  const useUser = (userId: number) => useQuery<GetUserResponse, Error>({
    queryKey: ["users", userId],
    queryFn: async () => { return await usersClient.getUser(userId) },
    enabled: !!userId
  })

  const useUpdateUser = (userId: number) => useMutation<GenericMessage, Error, UpdateUserRequest>(
    async (payload: UpdateUserRequest) => await usersClient.update(userId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  const useAddRole = () => useMutation<GenericMessage, Error, AddRoleToUserRequest>(
    async (payload: AddRoleToUserRequest) => await usersClient.addRole(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users")
        queryClient.invalidateQueries("whoami")
      }
    }
  )

  const useRoles = () => useQuery<GetRolesResponse, Error>({
    queryKey: ["users", "roles"],
    queryFn: usersClient.getRoles
  })

  return {
    useAddRole,
    useAllUsers,
    useUpdateUser,
    useUser,
    useWhoAmI,
    useRoles,
  }
}
