import ky from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { NEW_API_URL } from "./Config";
import { GetClubsResponse } from "./Descriptors";
import { getToken } from "./Login";

export class ClubsClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getAll = async (): Promise<GetClubsResponse> => {
    return this.api.get(`${NEW_API_URL}/clubs`).json()
  }
}
