import { useQuery } from "react-query";
import { ScorerEntry } from "../api/Descriptors";
import { GoalScorerAPI } from "../api/GoalScorer";

const scorersClient = new GoalScorerAPI()

export const useScorers = () => {
  // const queryClient = useQueryClient()

  // const useUpdateScorers  = (trainingId: number | string) => {
  //   return useQuery<TrainingData, Error>({
  //     queryKey: ["trainings", trainingId],
  //     queryFn: () => scorersClient.getSingleTraining(trainingId.toString())
  //   })
  // };


  const useGetAllScorers  = () => {
    return useQuery<ScorerEntry[], Error>({
      queryKey: ["goalscorers"],
      queryFn: () => scorersClient.getAll()
    })
  };

  return {
    useGetAllScorers,
  }
}
